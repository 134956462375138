// Webpack Scss styles loader - Please, keep here
// This will be removed before generating product files automatically
const css = require('./main.scss');

// Imports match polyfill
import polyfills from '../front/modules/polyfills';
import { notiferInstance as notifer } from '../shared/notifer/index';
import Clipboard from 'clipboard';

// SPECIFIC
import planPage from './checkout/planPage';
import { initSentry } from '../integrations/sentry';
import * as Sentry from '@sentry/browser';


if (process.env.NODE_ENV === "production") {
  initSentry();

  Sentry.configureScope(scope => {
    scope.setTag('scene', 'Account');
  });
}

if (process.env.NODE_ENV === 'development') {
  console.log('Working from website JS');
}


document.addEventListener("DOMContentLoaded", () => {

  // COMMENT: This is for the `publish_payment.html` template
  // Promo Code on Credit Card payment page
  const promoCodeCtrl = document.getElementById('promoCodeCtrl');
  if (promoCodeCtrl) {
    const promoWrapper = document.getElementById('promoWrapper');

    promoCodeCtrl.addEventListener('click', function (ev) {
      ev.preventDefault();
      promoCodeCtrl.classList.toggle('is-active');
      promoWrapper.classList.toggle('is-shown');
    });
  }

  // COMMNET: This is for the `publish_live.html` template
  const urlWrapper = document.getElementById('url-wrapper');

  if (urlWrapper) {
    const urlBox = urlWrapper.children[0];
    const clipboard = new Clipboard(urlBox);

    clipboard.on('success', function () {
      notifer.success('URL copied to clipboard');
    });

    clipboard.on('error', function () {
      notifer.error('Copying fiald. Try again.');
    });
  }

});