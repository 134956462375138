// COMPONENTS
import NotiferCard from './notiferCard';

// MODULE
var Notifer = (function () {

    // Determine which animationend event is supported
    function animationEndSelect() {
        var t;
        var el = document.createElement('fake');
        var transitions = {
            'transition': 'animationend',
            'OTransition': 'oAnimationEnd',
            'MozTransition': 'animationend',
            'WebkitTransition': 'webkitAnimationEnd'
        }

        for (t in transitions) {
            if (el.style[t] !== undefined) {
                return transitions[t];
            }
        }
    }


    var defaults = {
        delay: 2000
    };

    var _Notifer = {

        create: function (options) {
            var instance = Object.create(_Notifer);
            instance.init(options);
            return instance;
        },

        init: function (options) {
            this.options = Object.assign({}, defaults, options);
            // this.notifications = [];
            this.notiferEndDelay = this.options.delay;
            this.animationEnd = animationEndSelect();

            // console.log('Notifer', this);
            this.setup();
        },

        setup: function () {
            this.renderNotiferContainer();
            this.attachHandlers();
        },

        error: function (messageText) {
            let card = NotiferCard.create(messageText);
            card.classList.add('is-error');

            this.setExpiration(card);

            this.notiferContainer.appendChild(card);
        },

        success: function (messageText) {
            let card = NotiferCard.create(messageText);
            card.classList.add('is-success');

            this.setExpiration(card);

            this.notiferContainer.appendChild(card);
        },

        setExpiration: function (card) {
            let context = this;

            setTimeout(function () {
                card.classList.add('is-removing');
                setTimeout(function () {
                    // console.log('it happened;');
                    context.notiferContainer.removeChild(card);
                }, 1000);
                // console.log(card);
                // card.addEventListener(this.animationEnd, function (ev) {
                // (ev.target == card) && context.notiferContainer.removeChild(card);
                // console.log('it happened;');
                // });
            }, this.options.delay);

        },

        renderNotiferContainer: function () {
            let containerFrag = document.createDocumentFragment();
            let container = document.createElement('div');
            container.classList.add('bp-notifer');

            containerFrag.appendChild(container);
            document.body.appendChild(containerFrag);

            this.notiferContainer = container;
        },

        attachHandlers: function () {
            var context = this;

            //-------------------
        }

    };

    return _Notifer

})();


export default Notifer
