import Switcher from 'shared/switcher';

function getYearPlanRoundedPrice(val) {
  return isNaN(val) ? '' : Math.round(val / 12);
}

document.addEventListener('DOMContentLoaded', event => {
  // CASHING
  const planToggle = document.getElementById('planSwitch');
  if (planToggle) {
    // const planType = [...document.querySelectorAll('[data-type]')];
    const plans = [...document.querySelectorAll('.bp-plan')];

    const generateUrl = (linkHref, id) => {
      const url = linkHref.split('/');

      // Split on both billing and trailing "slash" (Example: url/plan/)
      url[url.length - 2] = id;

      return url.join('/');
    };

    //TODO: This should not be done this way
    // We need to set the price with JS right after the age starts.
    // because we are generating the origin prices with HTML TEmplate
    // and we need to do logic for diving and rounding the numbers.
    plans.forEach(plan => {
      const yearId = plan.dataset.yearId;
      const parsedYearValue = parseInt(plan.dataset.yearValue);
      const valueEl = plan.querySelector('[data-value]');
      const linkEl = plan.querySelector('[data-link]');

      if (valueEl) {
        valueEl.innerHTML = getYearPlanRoundedPrice(parsedYearValue);
      }
      if (linkEl) {
        linkEl.href = generateUrl(linkEl.href, yearId);
      }
    });

    Switcher.create(planToggle, {
      onToggle() {
        if (this.active === this.year) {
          setTimeout(() => {
            plans.forEach(plan => {
              const yearId = plan.dataset.yearId;
              const parsedYearValue = parseInt(plan.dataset.yearValue);
              const valueEl = plan.querySelector('[data-value]');
              const linkEl = plan.querySelector('[data-link]');

              if (valueEl) {
                valueEl.innerHTML = getYearPlanRoundedPrice(parsedYearValue);
              }
              if (linkEl) {
                linkEl.href = generateUrl(linkEl.href, yearId);
              }
            });
          }, 500);
        } else {
          setTimeout(() => {
            plans.forEach(plan => {
              const monthId = plan.dataset.monthId;
              const monthValue = plan.dataset.monthValue;
              const valueEl = plan.querySelector('[data-value]');
              const linkEl = plan.querySelector('[data-link]');

              if (valueEl) {
                valueEl.innerHTML = monthValue;
              }
              if (linkEl) {
                linkEl.href = generateUrl(linkEl.href, monthId);
              }
            });
          }, 500);
        }
      },
    });
  }

  //
  //  Prevent form double send
  //
  [...document.querySelectorAll('.PaymentForm')].map(form => {
    form.isSending = false;
    form.addEventListener('submit', evt => {
      if (form.isSending) {
        // Cancel request
        evt.preventDefault();
        return;
      }

      form.isSending = true;

      // Add processing text to button
      const btn = form.querySelector('.PaymentForm__submit');
      if (btn) {
        btn.disabled = true;
        btn.innerText = 'Processing..';
      }
    });
  });
});
