var Switcher = {
  onToggle: function() {},

  create: function(el, config) {
    let instance = Object.create(Switcher);
    instance.init(el, config);
    return instance;
  },

  init: function(el, config) {
    this.el = el;
    this.switch = el.querySelector('.bp-switch-checkbox');
    this.ctrls = [...el.querySelectorAll('[data-ctrl]')];
    this.month = el.querySelector('#monthCtrl');
    this.year = el.querySelector('#yearCtrl');
    this.active = el.querySelector('.is-active');
    this.onToggle = config && config.onToggle;

    // console.log(this);
    this.attachHandlers();
  },

  togglePlan: function(ev) {
    ev.preventDefault();
    // IF the toggle then end the behavior
    if (ev.target === this.active) return;

    // Remove and add proper classes ;)
    this.el.classList.toggle('switched');
    this.month.classList.toggle('is-active');
    this.year.classList.toggle('is-active');

    // console.log(this.el, this.month, this.year);

    // Assign the correct active for later checking
    if (this.active === this.month) {
      this.active = this.year;
    } else {
      this.active = this.month;
    }

    // If custom onToggle, trigger
    this.onToggle();
  },

  attachHandlers: function() {
    let context = this;

    //-------------
    this.ctrls.forEach(function(el) {
      el.addEventListener('click', context.togglePlan.bind(context));
    });
    this.switch.addEventListener('click', context.togglePlan.bind(context));
  },
};

export default Switcher;
