// COMPONENTS


// MODULE
var NotiferCard = (function() {

    var _NotiferCard = {

        create: function(messageText) {
            var instance = Object.create(_NotiferCard);
            return instance.renderCard(messageText);
        },

        renderCard: function(messageText) {
            let card = document.createElement('div');
                card.classList.add('bp-notifer-card');
            
            let content = document.createElement('span');
                content.classList.add('bp-notifer-content');
                content.innerText = messageText;

            card.appendChild(content);
            
            return card;
        },

    };

    return _NotiferCard;

})();

export default NotiferCard;